<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  created () {
    document.title = "Bank Mandiri";
  },
  data()
  {
    return {
      firebaseReady: false
    }
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title ? `Bank Mandiri - ${to.meta.title} ` : 'Bank Mandiri'
    }
  },
}
</script>


<style>
body{
  background-color: white;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
